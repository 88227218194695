import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import iconCircle from 'stile-shared/assets/icons/icon_circle.svg';
import iconCircleTick from 'stile-shared/assets/icons/icon_circle_tick.svg';
import iconRightArrow from 'stile-shared/assets/icons/icon_right_arrow.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';
import { Slider } from 'stile-shared/src/components/2024/common/Slider';
import { Underline } from 'stile-shared/src/components/2024/common/Underline';
import { Grid } from 'stile-shared/src/components/2024/layout/Grid';
import { Section } from 'stile-shared/src/components/2024/layout/Section';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

const NorthCarolinaLayout = styled<typeof PageLayout>(PageLayout)``;

const NorthCarolinaTopSection = styled.div`
  background-color: var(--stile-grey-1);
  @media (max-width: 768px) {
    background-color: transparent;
  }

  .content {
    display: flex;
    flex-direction: row;
    position: relative;
    padding-bottom: 145px;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      padding-bottom: 0;
      margin-top: 28px;
    }

    .--left {
      width: 45%;
      h1 {
        margin-top: 80px;
        margin-bottom: 60px;
      }

      @media (max-width: 768px) {
        width: 100%;
        h1 {
          margin-top: 0;
          margin-bottom: 25px;
        }
      }
    }

    .--right {
      width: 50%;
      .gatsby-image-wrapper {
        margin-top: 70px;
        border-radius: 32px;
        aspect-ratio: 1;
      }

      @media (max-width: 768px) {
        left: 0;
        right: 0;
        height: 390px;
        width: calc(100% + 50px);

        position: relative;
        margin-left: -30px;
        margin-right: -90px;
        margin-top: 35px;

        .gatsby-image-wrapper {
          height: 390px;
          width: calc(100% + 20px);
          border-radius: 0;
          margin-top: 0;
          aspect-ratio: default;
        }
      }
    }

    h6,
    ul {
      margin: 0;
      padding: 0;
      width: 75%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .underline {
      margin-bottom: 10px;
    }

    ul {
      width: 90%;
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        display: flex;
        align-items: start;
        margin-top: 20px;
        img {
          margin-right: 12px;
          margin-top: 2px;
        }
      }
    }
  }
`;

const AlignedSection = styled.div`
  background-color: var(--white);
  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .grid-layout {
      gap: 0;
      .left,
      .right {
        position: relative;
        .gatsby-image-wrapper {
          border-radius: 32px;
          aspect-ratio: 1;
        }

        h2,
        p {
          padding: 0;
          margin: 0;
          margin-bottom: 32px;
        }

        @media (max-width: 768px) {
          h2 {
            margin-bottom: 20px;
          }
        }

        .--buttons {
          a {
            @media (max-width: 768px) {
              width: 100%;
              text-align: center;
              justify-content: center;
            }

            &.--alignment {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
          /* a:first-child {
            margin-bottom: 10px;
          } */
        }
      }
      .left {
        grid-column: 1 / span 6;

        .--callout {
          width: 140px;
          height: 140px;
          position: absolute;
          top: -70px;
          right: -70px;
          border-radius: 50%;
          background-color: var(--stile-green-1);
          color: var(--stile-grey-8);
          padding: 24px 14px 40px 15px;
          strong {
            font-family: 'More Sugar';
            font-size: 63.83px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -2.553px;
          }
          span {
            display: block;
            text-align: center;
            font-size: 14.629px;
            font-weight: 600;
            line-height: 0%;
          }

          @media (max-width: 768px) {
            top: -35px;
            right: 35px;
            width: 100px;
            height: 100px;
            padding: 15px 8px 28px 9px;
            text-align: center;
            margin-left: -4px;

            strong {
              text-align: center;
              font-size: 41.5px;
              line-height: normal;
              letter-spacing: -1.893px;
            }
            span {
              font-size: 10.449px;
              line-height: 12.287px; /* 153.59% */
            }
          }
        }

        @media (max-width: 768px) {
          grid-column: 1 / span 12;
        }
      }
      .right {
        grid-column: 8 / span 5;

        @media (max-width: 768px) {
          grid-column: 1 / span 12;
          grid-row: 2;
          margin-top: 30px;
        }
      }
    }
  }
`;

const RealWorldSection = styled<typeof Section>(Section)`
  h2 {
    margin: 0 0 32px 0 !important;
    @media (max-width: 768px) {
      margin-bottom: 20px !important;
      text-align: left !important;
    }
  }

  @media (max-width: 768px) {
    padding: 60px 0 0 0;
    .content {
      padding-top: 0 !important;
    }
  }

  .--subheader {
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      margin-bottom: 40px;
      text-align: left !important;
    }
  }

  .showcase-content {
    grid-column: span 12;
    padding-bottom: 150px;
    @media (max-width: 768px) {
      padding-bottom: 0px;
    }
  }
  .grid-layout {
    margin-top: 0 !important;
    @media (max-width: 768px) {
      margin-top: 0 !important;
      padding-bottom: 70px;
    }
  }
`;

const NorthCarolinaScienceTestSection = styled<typeof Section>(Section)`
  .content {
    padding: 150px 0;

    @media (max-width: 768px) {
      padding: 60px 0 !important;
    }

    .grid-layout {
      margin-top: 0 !important;
    }

    h2 {
      margin: 0 0 32px 0 !important;
      @media (max-width: 768px) {
        text-align: left !important;
        margin: 0 0 20px 0 !important;
      }
    }

    .--subheader {
      grid-column: 4 / span 6;
      text-align: center;
      margin-bottom: 120px;
      @media (max-width: 768px) {
        grid-column: 1 / span 12;
        margin-bottom: 60px;
        text-align: left !important;
      }
    }

    .--side-by-side {
      grid-column: span 12;
      display: flex;
      flex-direction: row;
      gap: 80px;
      justify-content: center;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 40px;
      }

      .--left,
      .--right {
        width: 35%;
        background-color: var(--white);
        border-radius: 32px;
        position: relative;
        padding: 80px 40px 50px 50px;

        box-shadow: 0 0 10px 10px #fff;

        @media (max-width: 768px) {
          width: 100%;
          box-shadow: none;
          padding: 50px 25px 50px 25px;
        }

        .h8 {
          padding: 0;
          margin: 0;
        }
        .--rounded-heading {
          border-radius: 32px;
          color: var(--white);
          background-color: var(--stile-grey-8);
          position: absolute;
          display: inline-flex;
          padding: 9px 20px 10px 20px;
          justify-content: center;
          align-items: center;

          // position it top center but half way up itself
          top: 8%;
          left: 40%;
          transform: translate(-30%, -150%);
        }
        p {
          margin: 10px 0 30px 0;
          @media (max-width: 768px) {
            margin-bottom: 0;
          }

          padding: 0;
          ol {
            list-style: upper-alpha;
            padding-left: 20px;
            margin-top: 30px;
            margin-bottom: 0;
            li {
              padding-left: 20px;
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          ul {
            padding-left: 0px;
            margin-top: 40px;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 15px;
            li {
              display: flex;
              background: url(${iconCircle}) no-repeat left top;
              padding-left: 30px;
            }
          }
        }
      }

      .--left {
        .--rounded-heading {
          @media (max-width: 768px) {
            left: 30% !important;
            transform: translate(-20%, -150%) !important;
          }
        }
      }

      .--right {
        .--rounded-heading {
          background-color: var(--stile-green-2);
        }
      }
    }
  }
`;

const ExpertAssistance = styled.div`
  padding: 150px 0;
  background-color: var(--white);
  @media (max-width: 768px) {
    padding: 0 0 60px 0;
  }
  .content {
    border-radius: 32px;
    overflow: hidden;
    box-shadow: 0px 531.667px 149.028px 0px rgba(0, 0, 0, 0),
      0px 339.944px 136.139px 0px rgba(0, 0, 0, 0.01),
      0px 191.722px 115.194px 0px rgba(0, 0, 0, 0.03), 0px 85.389px 85.389px 0px rgba(0, 0, 0, 0.04),
      0px 20.944px 46.722px 0px rgba(0, 0, 0, 0.05);

    @media (max-width: 768px) {
      box-shadow: none;
      background-color: var(--white);
    }

    > div {
      padding: 60px;
      border-radius: 32px;
      border: 0.806px solid var(--stile-grey-1);
      background-color: var(--stile-grey-1);
      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: row;
      gap: 52px;

      @media (max-width: 768px) {
        background-color: var(--white);
        flex-direction: column-reverse;
        padding: 25px 25px 30px 25px;
        gap: 25px;
      }

      .--left,
      .--right {
        flex-basis: 50%;
        @media (max-width: 768px) {
          flex-basis: 100%;
        }
      }

      .--left {
        justify-items: center;
        align-content: center;
        .--headings {
          display: flex;
          flex-direction: column;
          gap: 22px;
          @media (max-width: 768px) {
          }
          .h9 {
            margin: 0;
            padding: 0;
          }
          h4 {
            margin: 0;
            padding: 0;
          }
        }
        .--copy {
          display: flex;
          flex-direction: column;
          gap: 10px;
          p {
            width: 71%;
            margin: 25px 0 15px 0;

            @media (max-width: 768px) {
              width: 100%;
              margin: 12px 0 20px 0;
            }
          }
          .button {
            div {
              margin-left: 6px;
            }
            &.mobile {
              display: none;

              @media (max-width: 768px) {
                display: flex;
              }
            }

            @media (max-width: 768px) {
              &.desktop {
                display: none;
              }
            }
          }
        }
      }

      .--right {
        .gatsby-image-wrapper {
          border-radius: 32px;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
`;

function TwentyTwentyFourOregonPage(props: PageProps) {
  return (
    <NorthCarolinaLayout {...props}>
      <NorthCarolinaTopSection>
        <div className="content">
          <div className="--left">
            <h1>North Carolina, welcome to Stile</h1>
            <h6 className="h7">
              The changes to the North Carolina Science Standards are big, bold, and very… NC.
              <br />
              Stile&apos;s got you.
            </h6>
            <Underline underlineNumber={12} />
            <ul>
              <li>
                <img src={iconCircleTick} alt="bullet point" />{' '}
                <h6 className="h7">Built for the new NC standards.</h6>
              </li>
              <li>
                <img src={iconCircleTick} alt="bullet point" />{' '}
                <h6 className="h7">
                  The only middle school curriculum approved by the DPI for 6th, 7th and 8th grades.
                </h6>
              </li>
            </ul>
          </div>
          <div className="--right">
            <StaticImage
              src="../../../../stile-shared/assets/images/states/north-carolina/top-image.jpg"
              alt="North Carolina Science Standards"
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
      </NorthCarolinaTopSection>

      <AlignedSection>
        <div className="content">
          <Grid>
            <div className="left">
              <StaticImage
                src="../../../../stile-shared/assets/images/states/north-carolina/standards.jpg"
                alt="image of north carolina"
              />
              <div className="--callout">
                <strong>DPI</strong>
                <span>APPROVED</span>
              </div>
            </div>
            <div className="right">
              <h2>Beautifully built for the North Carolina Science Standards</h2>
              <div className="--text">
                <p>
                  There&apos;s &apos;alignment.&apos; And then there&apos;s actual alignment. Thanks
                  to Stile&apos;s powerful and customizable platform, we took the very best of our
                  existing middle school science curriculum and blended it with brand-new lessons
                  tailored for North Carolina. The result is a seamless, structured, and sequenced
                  Middle School Science Curriculum for the state.
                  <br />
                  <br />
                  It&apos;s no wonder that Stile is.
                  <strong>the only complete middle school curriculum approved by the DPI.</strong>
                </p>
              </div>
              <div className="--buttons">
                {/* <Button
                  href="/oregon/standards"
                  size="small"
                  label={
                    <div>
                      <svg
                        style={{ marginRight: 10 }}
                        width="15"
                        height="18"
                        viewBox="0 0 15 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M3.88735 4.51562C3.51309 4.51562 3.20996 4.81875 3.20996 5.19302C3.20996 5.56728 3.51309 5.87041 3.88735 5.87041H6.59691C6.97118 5.87041 7.2743 5.56728 7.2743 5.19302C7.2743 4.81875 6.97118 4.51562 6.59691 4.51562H3.88735Z"
                            fill="#2B2B2B"
                          />
                          <path
                            id="Vector_2"
                            d="M3.88735 8.12866C3.51308 8.12866 3.20996 8.43179 3.20996 8.80605C3.20996 9.18032 3.51308 9.48344 3.88735 9.48344H11.1134C11.4868 9.48344 11.7908 9.18032 11.7908 8.80605C11.7908 8.43179 11.4868 8.12866 11.1134 8.12866H3.88735Z"
                            fill="#2B2B2B"
                          />
                          <path
                            id="Vector_3"
                            d="M3.20996 12.4188C3.20996 12.0446 3.51308 11.7415 3.88735 11.7415H11.1134C11.4868 11.7415 11.7908 12.0446 11.7908 12.4188C11.7908 12.7931 11.4868 13.0962 11.1134 13.0962H3.88735C3.51308 13.0962 3.20996 12.7931 3.20996 12.4188Z"
                            fill="#2B2B2B"
                          />
                          <path
                            id="Vector_4"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.5 2.48348C0.5 1.11177 1.61261 0 2.98434 0H7.65498C8.31375 0 8.9454 0.261635 9.41112 0.727355L13.7726 5.08801C14.2383 5.55456 14.5 6.18623 14.5 6.845V15.1287C14.5 16.5004 13.3874 17.6122 12.0157 17.6122H2.98436C1.61265 17.6122 0.500021 16.5004 0.500021 15.1287L0.5 2.48348ZM2.98434 1.35478C2.3603 1.35478 1.8548 1.86028 1.8548 2.48348V15.1287C1.8548 15.7519 2.3603 16.2574 2.98434 16.2574H12.0156C12.6397 16.2574 13.1452 15.7519 13.1452 15.1287V6.845C13.1452 6.82129 13.1443 6.79759 13.1426 6.77388H10.2096C8.83788 6.77388 7.72611 5.66211 7.72611 4.2904V1.35736C7.7024 1.35566 7.6787 1.35482 7.65499 1.35482L2.98434 1.35478ZM9.08086 2.31245L12.1867 5.41912H10.2095C9.58633 5.41912 9.08083 4.91363 9.08083 4.29043L9.08086 2.31245Z"
                            fill="#2B2B2B"
                          />
                        </g>
                      </svg>
                      View the DPI&apos;s review of Stile
                    </div>
                  }
                /> */}
                <Button
                  href="/states/north-carolina-alignment"
                  size="small"
                  label="View alignment to North Carolina’s standards"
                  className="--alignment"
                />
              </div>
            </div>
          </Grid>
        </div>
      </AlignedSection>

      {/* <HearFromSection>
        <div className="content">
          <Grid>
            <div className="left">
              <h2>Hear from North Carolina educators</h2>
              <div className="--text">
                <p>
                  Envisioned as a teacher&apos;s ultimate sidekick, Stile&apos;s technology is
                  designed to put teachers in the driver&apos;s seat. Abby, Jenn, Stephen, and Jim
                  share some of their favorite moments teaching with Stile.
                </p>
              </div>
            </div>
            <div className="right">
              <StaticImage
                src="../../../../stile-shared/assets/images/states/oregon/video.jpg"
                alt="thumbnail from a video"
              />
              <svg
                className="playButton"
                xmlns="http://www.w3.org/2000/svg"
                width="78"
                height="78"
                viewBox="0 0 78 78"
                fill="none"
              >
                <circle opacity="0.5" cx="39" cy="39" r="39" fill="white" />
                <path d="M49 39L34 47.6603L34 30.3397L49 39Z" fill="#2B2B2B" />
              </svg>
            </div>
          </Grid>
        </div>
      </HearFromSection> */}

      <RealWorldSection
        backgroundColor="--stile-green-2"
        headingColor="--stile-grey-8"
        headingContent="Real-world phenomena from North Carolina’s backyard"
        textColor="--stile-grey-8"
      >
        <div className="--subheader">
          Many of Stile&apos;s phenomena are drawn from wonders all around the world. After all,
          it&apos;s important that students grow into global citizens. But sometimes, there&apos;s
          nothing more fascinating than investigating phenomena right where you live. Stile&apos;s
          curriculum for North Carolina offers a balanced blend of local and global phenomena.
        </div>
        <div className="showcase-content">
          <Slider
            tabs={[
              'Lake Norman algae',
              'Venus flytrap investigation',
              'North Carolina food web',
              'Charlotte gold rush',
            ]}
            headings={[
              'The Lake Norman algae mystery',
              'The wonders of the Venus flytrap',
              'Tracing energy through local food webs',
              'Unearthing chemistry in North Carolina’s first gold rush',
            ]}
            paragraphs={[
              'In our Water Safety unit, students dive into the mystery of blue-green algae blooms in Lake Norman, Charlotte, analyzing water quality reports to find out if the lake is safe for swimming (or, you know, playing Marco Polo). Talk about making a splash in science!',
              'Students explore the Venus flytrap, North Carolina’s very own carnivorous marvel, in our Plant Adaptations unit. They get hands-on to uncover what makes this rare plant snap shut and how it captures its prey. It’s a bite of science they won’t forget!',
              'Local ecosystems come to life in our Food Webs unit. Students build models that trace the flow of energy through North Carolina’s food chains, discovering how the intricate balance of nature is maintained in their own very backyard.',
              'The Periodic Table unit takes students back to Charlotte’s historic gold rush, where they unearth the chemistry of elements and compounds. They dig deep (get it?) to discover how the gold hunt shaped the city’s history while putting their chemistry skills to the test.',
            ]}
            images={[
              <StaticImage
                key={0}
                src="../../../../stile-shared/assets/images/states/north-carolina/slider-1.png"
                alt="feedback"
              />,
              <StaticImage
                key={1}
                src="../../../../stile-shared/assets/images/states/north-carolina/slider-2.png"
                alt="feedback"
              />,
              <StaticImage
                key={2}
                src="../../../../stile-shared/assets/images/states/north-carolina/slider-3.png"
                alt="feedback"
              />,
              <StaticImage
                key={3}
                src="../../../../stile-shared/assets/images/states/north-carolina/slider-4.png"
                alt="feedback"
              />,
            ]}
          />
        </div>
      </RealWorldSection>

      <NorthCarolinaScienceTestSection
        headingContent="Prepare your students for the North Carolina Science Test"
        backgroundColor="--stile-grey-1"
      >
        <>
          <div className="--subheader">
            The North Carolina End-of-Grade science test aims to measure students' science knowledge
            and critical thinking ability. Stile’s curriculum precisely scaffolds both of these,
            providing students with plenty of opportunities to practice and excel on the North
            Carolina Science Test.
          </div>
          <div className="--side-by-side">
            <div className="--left">
              <h6 className="h8 --rounded-heading">North Carolina Science Test</h6>
              <h6 className="h8">Question</h6>
              <p className="--small">
                Which statement is true about a chemical reaction?
                <ol>
                  <li>The physical states of the atoms in the reactants determine the products.</li>
                  <li>Some of the atoms in the reactants will be part of the products.</li>
                  <li>The number of atoms in the reactants determines the products.</li>
                  <li>All of the atoms in the reactants will be a part of the products.</li>
                </ol>
              </p>
            </div>
            <div className="--right">
              <h6 className="h8 --rounded-heading">Stile Science Test</h6>
              <h6 className="h8">Question</h6>
              <p className="--small">
                A chemical reaction can be used to turn water into two gases: hydrogen and oxygen.
                Determine which of the following statements about this reaction are true.
                <ol>
                  <li>Hydrogen is a reactant</li>
                  <li>Water is a reactant</li>
                  <li>A compound is converted into two elements</li>
                  <li>An element is converted into two compounds</li>
                </ol>
              </p>
            </div>
          </div>
        </>
      </NorthCarolinaScienceTestSection>

      <ExpertAssistance>
        <div className="content">
          <div>
            <div className="--left">
              <div className="--headings">
                <h4>Pilot Stile with Charlotte’s own expert, Mark Picardo</h4>
              </div>
              <div className="--copy">
                <p>
                  Stile is all about empowering teachers with the best support. In the Carolinas,
                  that means teaming up with Mark Picardo, a seasoned educator with extensive
                  experience in teaching, administration, and instructional coaching. Together,
                  teachers are supported (and lessons are supercharged!).
                </p>
                <Button
                  href="/why-choose-stile/oregon-pilot/"
                  className="desktop"
                  label={
                    <>
                      <span>Learn more about Stile’s supported pilots</span>
                      <Icon src={iconRightArrow} iconSize="fontSize" />
                    </>
                  }
                />
                <Button
                  href="/why-choose-stile/oregon-pilot/"
                  className="mobile"
                  label={
                    <>
                      <span>Learn about our supported pilots</span>
                      <Icon src={iconRightArrow} iconSize="fontSize" />
                    </>
                  }
                />
                <Button
                  href="/set-up-trial/"
                  label={
                    <>
                      <span>Start a conversation with us</span>
                    </>
                  }
                />
              </div>
            </div>
            <div className="--right">
              <StaticImage
                src="../../../../stile-shared/assets/images/states/north-carolina/expert.jpg"
                alt="Mark Picardo"
                quality={100}
                height={1250}
              />
            </div>
          </div>
        </div>
      </ExpertAssistance>
    </NorthCarolinaLayout>
  );
}

export default TwentyTwentyFourOregonPage;
